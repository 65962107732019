import React from "react"
import withLayout from "../layout"

import Authors from "../components/Authors"

const AuthorsandContacts = () => (
  <>
    <Authors />
  </>
)

const customProps = {
  localeKey: "AuthorsAndContacts",
}

export default withLayout(customProps)(AuthorsandContacts)
