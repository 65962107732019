import React from "react"
import { FormattedHTMLMessage } from "react-intl"
import ImageAuthors from "../ImageAuthors"

const Authors = () => {
  return (
    <section className='section'>
      <div className='container'>
        <h1 className='is-size-2 has-text-weight-bold'>
          <FormattedHTMLMessage id='AuthorsAndContacts_Title' />
        </h1>
        <div className='mt-15 mb-15'>
          <FormattedHTMLMessage id='AuthorsAndContacts_Main' />
        </div>
        <ImageAuthors />
        <div className='mt-15'>
          <FormattedHTMLMessage id='AuthorsAndContacts_Contacts' />
        </div>
      </div>
    </section>
  )
}

export default Authors
